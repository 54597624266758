import { useDispatch, useSelector } from 'react-redux'
import { IUpdateChannelRequest } from '../../../../state/modules/channels/interfaces'
import {
  uiChannelsActions,
  uiChannelsSelectors,
} from '../../../../state/modules/ui'
import { sitesSelectors } from '../../../../state/modules/sites'
import {
  channelActions,
  channelSelectors,
} from '../../../../state/modules/channels'
import { omit, reduce, uniqBy, upperFirst } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Category, ControlElement, JsonSchema } from '@jsonforms/core'
import { useAtom } from 'jotai'
import Ajv, { ErrorObject } from 'ajv'
import { usePrevious } from 'react-use'
import { JsonFormsReactProps } from '@jsonforms/react'
import {
  editingAtom,
  formDataAtom,
  isCloseChannelModalActiveAtom,
} from './channelFormAtoms'
import { sanitizeChannelFormValues } from './channelFormUtils'

export type Props = {
  channelId: string
  ajv: Ajv
}

const fieldsToOmit = ['count', 'slug', '_changelog', 'forex_feed', 'language']

const useChannelForm = ({ channelId, ajv }: Props) => {
  const dispatch = useDispatch()
  const channel = useSelector(state =>
    channelSelectors.getChannelById(state)(channelId),
  )
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const channelSchema = useSelector(channelSelectors.getChannelsSchema)
  const isNewChannel = channelId === 'new'
  const isViewingChannel = useSelector(uiChannelsSelectors.isViewingChannel)
  const schema: JsonSchema = useMemo(() => {
    const _schema = omit(channelSchema.schema, 'ui')
    const properties = omit(_schema.properties, fieldsToOmit)
    return {
      ..._schema,
      properties,
    }
  }, [channelSchema])

  const uiSchema = useMemo(() => {
    return channelSchema?.schema?.ui
  }, [channelSchema])

  const chargesProperties = useSelector(
    channelSelectors.getChargesPropertiesFromSchema,
  )
  const defaultAccountMode = useSelector(
    channelSelectors.getDefaultAccountModeFromSchema,
  )
  const getStatusDefaultByAccountMode = useSelector(
    channelSelectors.getStatusDefaultByAccountMode,
  )

  const setInitialValues = useMemo(
    () => (channel: any) => {
      if (channel !== undefined) {
        return {
          ...channel,
          descriptor: channel.descriptor || '',
          primary_mail: channel.primary_mail || '',
          chargeback_mail: channel.chargeback_mail || '',
          _tmt_charges: reduce(
            chargesProperties,
            (result: any, val: any, key: string) => {
              result[key] =
                channel._tmt_charges[key] === null
                  ? 0
                  : channel._tmt_charges[key]
              return result
            },
            {},
          ),
        }
      }

      const fieldNames = (uiSchema?.elements || [])
        .map((group: Category) =>
          group.elements.map(el => (el as ControlElement).scope.split('/')[2]),
        )
        .flat()

      const defaults = fieldNames.reduce((result: any, val: string) => {
        if (schema?.properties?.[val]?.default !== undefined) {
          result[val] = schema.properties[val].default
        }
        return result
      }, {})

      return {
        ...defaults,
        name: '',
        _currency_gateway_keys: [],
        _identifier: '',
        _tmt_charges: reduce(
          chargesProperties,
          (result: any, val: any, key: string) => {
            result[key] = val.default
            return result
          },
          {},
        ),
        channel_status: getStatusDefaultByAccountMode(defaultAccountMode),
        currencies: '',
        descriptor: '',
        primary_mail: '',
        chargeback_mail: '',
        receipt_label: '',
        suppliers: [],
      }
    },
    [],
  )

  const initialValues = setInitialValues(channel)

  const [data, setData] = useAtom(formDataAtom)

  const isDirty = useMemo(() => {
    return JSON.stringify(data) !== JSON.stringify(initialValues)
  }, [data, initialValues])

  // set initial values when channelId changes
  useEffect(() => {
    const initialValues = setInitialValues(channel)
    setData(initialValues)
  }, [channel])

  // Validation
  const validate = ajv.compile(schema)
  useEffect(() => {
    validate(data)
  }, [data])

  useEffect(() => {
    if (isViewingChannel) {
      // reset initial values if the booking ID changes
      if (
        initialValues?.id &&
        parseInt(initialValues.id) !== parseInt(data?.id)
      ) {
        setData(initialValues)
      }
    }
  }, [data, isViewingChannel, initialValues])

  const [isEditing, setIsEditing] = useAtom(editingAtom)
  const [isCloseChannelModalActive, setIsCloseChannelModalActive] = useAtom(
    isCloseChannelModalActiveAtom,
  )
  const [additionalErrors, setAdditionalErrors] = useState<ErrorObject[]>([])
  const isFetchingChannel = useSelector(uiChannelsSelectors.isFetching)
  const isSavingChannel = useSelector(uiChannelsSelectors.isSaving)
  const isCreatingChannel = useSelector(uiChannelsSelectors.isCreating)
  const isDeletingChannel = useSelector(uiChannelsSelectors.isDeleting)
  const wasSavingChannel = usePrevious(isSavingChannel)
  const wasCreatingChannel = usePrevious(isCreatingChannel)
  const wasDeletingChannel = usePrevious(isDeletingChannel)

  useEffect(() => {
    if (wasSavingChannel && !isSavingChannel) {
      setIsEditing(false)
    }
  }, [isSavingChannel, wasSavingChannel])

  const addAdditionalError = (instancePath: string, message: string) => {
    const newError: ErrorObject = {
      // AJV style path to the property in the schema
      instancePath,
      // message to display
      message,
      schemaPath: '',
      keyword: '',
      params: {},
    }
    setAdditionalErrors(errors => uniqBy([...errors, newError], 'instancePath'))
  }

  const removeAdditionalError = (instancePath: string) => {
    setAdditionalErrors(errors =>
      errors.filter(e => e.instancePath !== instancePath),
    )
  }

  const handleEmailValidation = useCallback(
    (emails: string[], instancePath: string) => {
      const invalidEmails = emails
        .filter((email: any) => !/^.+@.+\..+$/.test(email))
        .filter(Boolean)
      if (invalidEmails.length) {
        addAdditionalError(
          instancePath,
          `Invalid email(s): ${invalidEmails.join(', ')}`,
        )
      } else {
        removeAdditionalError(instancePath)
      }
    },
    [],
  )

  const handleValidation = useCallback(() => {
    const primaryEmails = data?.primary_mail?.split(',') || []
    handleEmailValidation(primaryEmails, '/primary_mail')

    const chargebackEmails = data?.chargeback_mail?.split(',') || []
    handleEmailValidation(chargebackEmails, '/chargeback_mail')

    if (data?._trust_override < 0) {
      addAdditionalError(
        '/_trust_override',
        'Must be greater than or equal to 0',
      )
    } else {
      removeAdditionalError('/_trust_override')
    }

    if (data?.suppliers.length === 0 && data?.beneficiary_id) {
      addAdditionalError(
        '/beneficiary_id',
        'You cannot add a beneficiary ID to a channel with no suppliers',
      )
    } else {
      removeAdditionalError('/beneficiary_id')
    }
  }, [data, handleEmailValidation])

  useEffect(() => {
    handleValidation()
  }, [data])

  const handleChange: JsonFormsReactProps['onChange'] = useCallback(
    ({ data: newData, errors }) => {
      if (
        newData !== null &&
        JSON.stringify(newData) !== JSON.stringify(data)
      ) {
        setData(newData)
      }
    },
    [handleValidation, setData],
  )

  const getLoadingState = useCallback(() => {
    if (isFetchingChannel) {
      return 'loading'
    }

    if (isSavingChannel) {
      return 'saving'
    }
    if (isCreatingChannel) {
      return 'creating'
    }

    if (isDeletingChannel) {
      return 'deleting'
    }

    return 'idle'
  }, [isFetchingChannel, isSavingChannel, isCreatingChannel, isDeletingChannel])

  const createChannel = useCallback(() => {
    const sanitizedValues = sanitizeChannelFormValues(data)
    return dispatch(channelActions.createChannel(sanitizedValues))
  }, [data, dispatch])

  const updateChannel = useCallback(() => {
    const sanitizedValues = sanitizeChannelFormValues(data)
    dispatch(channelActions.updateChannel(omit(sanitizedValues, fieldsToOmit)))
  }, [data, dispatch])

  const deleteChannel = useCallback(
    () => dispatch(channelActions.deleteChannel({ id: channelId })),
    [channelId, dispatch],
  )

  const closeChannel = useCallback(() => {
    const sanitizedValues = sanitizeChannelFormValues(data)
    dispatch(
      channelActions.updateChannel({
        ...omit(sanitizedValues, fieldsToOmit),
        account_mode: 'closed',
      } as IUpdateChannelRequest),
    )
    setIsCloseChannelModalActive(false)
  }, [data])

  const handleSubmit = useCallback(() => {
    if (
      initialValues.account_mode !== 'closed' &&
      data.account_mode === 'closed'
    ) {
      setIsCloseChannelModalActive(true)
      return
    }

    if (channelId === 'new') {
      createChannel()
    } else {
      updateChannel()
    }
  }, [channelId, updateChannel, createChannel])

  const clearServerErrors = () =>
    dispatch(uiChannelsActions.clearServerErrors())

  return {
    channel,
    isNewChannel,
    isViewingChannel,
    currentSitePath,
    initialValues,
    setInitialValues,
    errors: validate.errors,
    isValid: (validate.errors || []).length === 0,
    schema,
    uiSchema,
    data,
    setData,
    isDirty,
    isEditing,
    setIsEditing,
    isCloseChannelModalActive,
    setIsCloseChannelModalActive,
    isSavingChannel,
    isCreatingChannel,
    isDeletingChannel,
    wasSavingChannel,
    wasCreatingChannel,
    wasDeletingChannel,
    additionalErrors,
    addAdditionalError,
    getLoadingState,
    handleChange,
    createChannel,
    updateChannel,
    deleteChannel,
    handleSubmit,
    closeChannel,
  }
}

export default useChannelForm
