import React from 'react'
import {
  CellProps,
  isMultiLineControl,
  isStringControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import merge from 'lodash/merge'
import {
  VanillaRendererProps,
  withVanillaCellProps,
} from '@jsonforms/vanilla-renderers'
import { TextAreaInput, TextInput } from '../../../../views/components'
import styled from 'styled-components'
import { colors } from '../../../../views/styles/variables'

const TextAreaCell = (props: CellProps & VanillaRendererProps) => {
  const {
    config,
    data,
    className,
    id,
    enabled,
    uischema,
    schema,
    path,
    handleChange,
    errors,
  } = props
  // const maxLength = schema?.maxLength || 0
  const appliedUiSchemaOptions = merge({}, config, uischema.options)

  const isFormFieldDisabled = () => {
    if (!enabled) {
      return true
    }
    const isReadOnly = (schema as any)?.readonly === true
    if (isReadOnly) {
      return true
    }
    return false
  }

  return (
    <TextAreaInput
      name={id}
      value={data || ''}
      onChange={ev =>
        handleChange(path, ev.target.value === '' ? undefined : ev.target.value)
      }
      className={className}
      id={id}
      disabled={isFormFieldDisabled()}
      placeholder={appliedUiSchemaOptions.placeholder}
      // maxLength={maxLength}
    />
  )
}

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const textAreaCellTester: RankedTester = rankWith(4, isMultiLineControl)

const StyledTextAreaCell = styled(TextAreaCell)`
  border-color: ${colors.blue.dark};
  font-size: 15px !important;
`

export default withJsonFormsCellProps(withVanillaCellProps(StyledTextAreaCell))
