import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sitesSelectors } from '../../../../state/modules/sites'
import { transactionsConstants } from '../../../../state/modules/transactions'
import { uiNotificationActions } from '../../../../state/modules/ui'
import { getToken } from '../../../../utils/auth'
import { redirect } from '../../../../utils/router'
import UploadCSVModal from '../../organisms/UploadCSVModal/UploadCSVModal'

interface ITransactionsUploader {
  setUploadCSVModalOpen(open: boolean): void
  isUploadCSVModalOpen: boolean
}

export const TransactionsUploader = ({
  setUploadCSVModalOpen,
  isUploadCSVModalOpen,
}: ITransactionsUploader) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)

  const handleSubmit = (values: { csv: File }) => {
    setIsLoading(true)
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${getToken()}`)

    const formdata = new FormData()
    formdata.append('csv', values.csv, values.csv.name)
    formdata.append('fail_all_on_error', 'true')

    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }

    fetch(
      `${transactionsConstants.ENDPOINTS.BASE}/import`.replace(
        ':sitePath',
        currentSitePath,
      ),
      requestOptions,
    )
      .then(response => response.json())
      .then(result => {
        setIsLoading(false)
        if (result.id) {
          redirect(`/${currentSitePath}/uploads`)
        } else if (result.code === 'rest_invalid_param') {
          const message = result.data.params.csv
          dispatch(
            uiNotificationActions.addNotification({ message, type: 'error' }),
          )
        } else if (result.code === 'auth_error') {
          const message = result.message
          dispatch(
            uiNotificationActions.addNotification({ message, type: 'error' }),
          )
        }
      })
      .catch(error => {
        setIsLoading(false)
        console.log('error', error)
      })
  }

  return (
    <UploadCSVModal
      isOpen={isUploadCSVModalOpen}
      onClose={() => setUploadCSVModalOpen(false)}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      initialValues={{ csv: null }}
    />
  )
}
