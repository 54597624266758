import { JsonFormsReactProps } from '@jsonforms/react'
import Ajv, { ErrorObject } from 'ajv'
import { isNil, omit, omitBy, uniqBy } from 'lodash'
import { atom, useAtom } from 'jotai'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  bookingActions,
  bookingSelectors,
} from '../../../../state/modules/bookings'
import { sitesSelectors } from '../../../../state/modules/sites'
import { uiBookingsSelectors } from '../../../../state/modules/ui'
import {
  channelActions,
  channelSelectors,
} from '../../../../state/modules/channels'
import { usePrevious } from 'react-use'

const bookingFormDataAtom = atom<any>(null)
const editingAtom = atom<boolean>(false)

const useBookingForm = ({
  bookingId,
  ajv,
}: {
  bookingId: string
  ajv: Ajv
}) => {
  const dispatch = useDispatch()
  const booking = useSelector(state =>
    bookingSelectors.getBookingById(state)(bookingId),
  )
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const bookingsSchema = useSelector(bookingSelectors.getBookingsSchema)
  const isViewingBooking = useSelector(uiBookingsSelectors.isViewingBooking)
  const canUserCreateBookings = useSelector(state =>
    sitesSelectors.getCurrentSitePermission(state as never)('create_bookings'),
  )

  const schema = useMemo(() => {
    return omit(bookingsSchema.schema, 'ui')
  }, [bookingsSchema])

  const uiSchema = useMemo(() => {
    return bookingsSchema?.schema?.ui
  }, [bookingsSchema])

  const setInitialValues = useCallback(
    (booking: any) =>
      booking !== null
        ? omitBy(
            omit(
              {
                ...booking,
                countries: booking.countries?.split(','),
                // total: convertFromCents(booking.total),
                channels: parseInt(booking.channels),
              },
              ['country'],
            ),
            isNil,
          )
        : {
            date: moment().add(1, 'day').format('YYYY-MM-DD'),
            date_start: moment().format('YYYY-MM-DD'),
            total_unpaid: 0,
          },
    [],
  )

  const initialValues = setInitialValues(booking)

  const [data, setData] = useAtom(bookingFormDataAtom)

  const bookingChannel = useSelector(state =>
    channelSelectors.getChannelById(state)(data?.channels),
  )

  useEffect(() => {
    if (!bookingChannel && booking?.channels) {
      dispatch(channelActions.requestChannel({ id: booking.channels }))
    }
  }, [bookingChannel, booking?.channels])

  // Set initial values when bookingId changes
  useEffect(() => {
    setData(initialValues)
  }, [bookingId])

  // Validation
  const validate = ajv.compile(bookingsSchema.schema)
  useEffect(() => {
    validate(data)
  }, [data])

  useEffect(() => {
    if (isViewingBooking) {
      // reset initial values if the booking ID changes
      if (
        initialValues?.id &&
        parseInt(initialValues.id) !== parseInt(data?.id)
      ) {
        setData(initialValues)
      }
    }
  }, [data, isViewingBooking, initialValues])

  const [isEditing, setIsEditing] = useAtom(editingAtom)
  const [additionalErrors, setAdditionalErrors] = useState<ErrorObject[]>([])
  const isFetchingBooking = useSelector(uiBookingsSelectors.isFetchingBooking)
  const isSavingBooking = useSelector(uiBookingsSelectors.isSaving)
  const isCreatingBooking = useSelector(uiBookingsSelectors.isCreatingBooking)
  const isDeletingBooking = useSelector(uiBookingsSelectors.isDeleting)
  const wasSavingBooking = usePrevious(isSavingBooking)
  const wasCreatingBooking = usePrevious(isCreatingBooking)
  const wasDeletingBooking = usePrevious(isDeletingBooking)

  useEffect(() => {
    if (wasSavingBooking && !isSavingBooking) {
      setIsEditing(false)
    }
  }, [isSavingBooking, wasSavingBooking])

  useEffect(() => {
    if (wasCreatingBooking && !isCreatingBooking) {
      setIsEditing(false)
    }
  }, [isCreatingBooking, wasCreatingBooking])

  const addAdditionalError = (instancePath: string, message: string) => {
    const newError: ErrorObject = {
      // AJV style path to the property in the schema
      instancePath,
      // message to display
      message,
      schemaPath: '',
      keyword: '',
      params: {},
    }
    setAdditionalErrors(errors => uniqBy([...errors, newError], 'instancePath'))
  }

  const handleStartEndDateValidation: JsonFormsReactProps['onChange'] = ({
    data,
  }) => {
    const startDate = data?.date_start
    const endDate = data?.date
    if (startDate && endDate) {
      const startDateMoment = moment(startDate)
      const endDateMoment = moment(endDate)
      if (startDateMoment.isAfter(endDateMoment)) {
        addAdditionalError('/date', 'End date must be after start date')
      } else {
        setAdditionalErrors(errors =>
          errors.filter(error => error.instancePath !== '/date'),
        )
      }
    }
  }

  const getChannelById = useSelector(channelSelectors.getChannelById)

  const getNewDataOnChannelChange: JsonFormsReactProps['onChange'] = ({
    data,
  }) => {
    const channel = getChannelById(data?.channels)
    if (channel?.currencies) {
      return {
        ...data,
        currencies: channel.currencies,
      }
    }

    return data
  }

  const handleChange: JsonFormsReactProps['onChange'] = useCallback(
    ({ data, errors }) => {
      handleStartEndDateValidation({ data, errors })
      const newData = getNewDataOnChannelChange({ data })
      if (JSON.stringify(newData) !== JSON.stringify(data)) {
        setData(newData)
      } else {
        if (data !== null) {
          setData(data)
        }
      }
    },
    [data],
  )

  const transformBookingRequest = useCallback(
    (data: any) => {
      const payload = omit(
        {
          ...data,
          countries: data.countries?.join(','),
          date: moment(data.date).format('YYYY-MM-DD'),
        },
        ['_links', 'internal_comments', 'external_comments'],
      )

      if (data.date_start) {
        payload.date_start = moment(data.date_start).format('YYYY-MM-DD')
      }

      return payload
    },
    [data],
  )

  const getLoadingState = useCallback(() => {
    if (isFetchingBooking) {
      return 'loading'
    }

    if (isSavingBooking) {
      return 'saving'
    }
    if (isCreatingBooking) {
      return 'creating'
    }

    if (isDeletingBooking) {
      return 'deleting'
    }

    return 'idle'
  }, [isFetchingBooking, isSavingBooking, isCreatingBooking, isDeletingBooking])

  const createBooking = useCallback(() => {
    dispatch(bookingActions.requestCreateBooking(transformBookingRequest(data)))
  }, [data, dispatch])

  const updateBooking = useCallback(() => {
    dispatch(bookingActions.requestUpdateBooking(transformBookingRequest(data)))
  }, [data, dispatch])

  const deleteBooking = useCallback(() => {
    dispatch(bookingActions.requestDeleteBooking({ id: bookingId }))
  }, [bookingId, dispatch])

  return {
    initialValues,
    setInitialValues,
    errors: validate.errors,
    isValid: (validate.errors || []).length === 0,
    currentSitePath,
    schema,
    uiSchema,
    isViewingBooking,
    canUserCreateBookings,
    booking,
    data,
    setData,
    isEditing,
    setIsEditing,
    additionalErrors,
    getLoadingState,
    isSavingBooking,
    isCreatingBooking,
    isDeletingBooking,
    addAdditionalError,
    handleChange,
    createBooking,
    updateBooking,
    deleteBooking,
  }
}

export default useBookingForm
