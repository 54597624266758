import { Field, Form, Formik, FormikProps } from 'formik'
import React, { FC } from 'react'
import * as yup from 'yup'
import {
  Button,
  FormField,
  Loader,
  Select,
  Text,
  TextInput,
} from '../../views/components'
import { colors } from '../../views/styles/variables'
import styled from 'styled-components'
import { Box } from 'grommet'
import { addDays, format } from 'date-fns'
import { useCreateApiToken } from './data'

type Values = {
  name: string
  expires_at?: string
}

type Props = {
  className?: string
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
})

const getExpirationDateString = (expiresAt?: string) => {
  const formatStr = 'EEE, MMM dd yyyy'
  const date = Date.now()

  switch (expiresAt) {
    case '7 days':
      return format(addDays(date, 7), formatStr)
    case '30 days':
      return format(addDays(date, 30), formatStr)
    case '90 days':
      return format(addDays(date, 90), formatStr)
    default:
      return '0'
  }
}

const transformPayload = (values: Values) => {
  const expiresAt = getExpirationDateString(values.expires_at)
  if (expiresAt === '0') {
    return { name: values.name }
  }

  return {
    name: values.name,
    expires_at: format(new Date(expiresAt), 'yyyy-MM-dd'),
  }
}

const CreateApiTokenForm: FC<Props> = ({ className }) => {
  const [newToken, setNewToken] = React.useState<string | null>(null)

  const { mutate, isLoading } = useCreateApiToken({
    onSuccess: data => {
      setNewToken(data.token)
    },
  })

  const handleSubmit = (values: Values) => {
    mutate(transformPayload(values))
  }

  if (isLoading) {
    return (
      <Box className={className} pad="medium">
        <Loader text="Creating API Token..." />
      </Box>
    )
  }

  if (newToken) {
    return (
      <Box className={className} pad="medium">
        <Text tag="h3" color={colors.blue.dark}>
          New API Token
        </Text>
        <Text tag="p" color={colors.grey.dark}>
          Your new API token is below.{' '}
          <strong>
            This is the only time it will be shown so please copy it now!
          </strong>
        </Text>
        <TextInput name="token" copyToClipboard={true} value={newToken} />
      </Box>
    )
  }

  return (
    <Box className={className}>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={{ name: '', expires_at: '30 days' }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
        }: FormikProps<Values>) => {
          return (
            <Form>
              <Text tag="h3" color={colors.blue.dark}>
                New API Token
              </Text>
              <FormField
                label="Name"
                className="field"
                error={touched?.name && errors.name}
              >
                <Field
                  component={TextInput}
                  name="name"
                  className="field"
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                <small>What's this token for?</small>
              </FormField>
              <FormField label="Expiration" className="type field">
                <Field
                  component={Select}
                  id="expires_at"
                  name="expires_at"
                  onChange={(val: string) => setFieldValue('expires_at', val)}
                  onBlur={handleBlur}
                  options={['7 days', '30 days', '90 days', 'No expiration']}
                  value={values.expires_at}
                />
                <small>
                  {values.expires_at !== 'No expiration' && (
                    <>
                      The token will expire on{' '}
                      {getExpirationDateString(values.expires_at)}
                    </>
                  )}
                </small>
              </FormField>
              <footer>
                <Button
                  styleType="secondary"
                  label="Generate token"
                  size="small"
                  type="submit"
                />
              </footer>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default styled(CreateApiTokenForm)`
  form {
    padding: 24px;

    h3 {
      margin: 0 0 10px 0;
      padding: 0 0 10px 0;
      border-bottom: 1px solid ${colors.grey.lighter};
    }

    > .field {
      margin-bottom: 16px;

      label {
        min-width: 150px;
      }
    }

    #expires_at {
      width: 100%;
    }

    footer {
      border-top: 1px solid ${colors.grey.lighter};
      text-align: right;
      padding-top: 10px;
      margin-top: 10px;
    }

    footer button {
      font-size: 1rem;
      padding: 6px 8px;
    }
  }
`
