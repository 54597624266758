import Ajv from 'ajv'
import { Box } from 'grommet'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { authSelectors } from '../../../../state/modules/auth'
import { redirect } from '../../../../utils/router'
import Button from '../../atoms/Button/Button'
import Text from '../../atoms/Text/Text'
import useSettingsPageForm from './useSettingsPageForm'

type Props = {
  currentSitePath: string
  ajv: Ajv
}

const SettingsPageHeader = ({ currentSitePath, ajv }: Props) => {
  const {
    isEditing,
    setIsEditing,
    isDirty,
    isNewSite,
    isValid,
    errors,
    handleSubmit,
  } = useSettingsPageForm({
    currentSitePath,
    ajv,
  })
  console.log('errors', errors)
  const isAdmin = useSelector(authSelectors.isAdmin)

  const handleCancel = useCallback(() => {
    setIsEditing(false)
    if (isNewSite) {
      redirect(`/${currentSitePath}/settings`)
    } else {
      // resetForm()
    }
  }, [currentSitePath, isNewSite])

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      className="section-header"
    >
      <Text tag="h2" color="white">
        Site Settings
      </Text>
      <Box direction="row" className="actions">
        {!isEditing && !isNewSite ? (
          <>
            <Button
              styleType="secondary"
              label="Edit Site Settings"
              size="small"
              type="button"
              onClick={() => setIsEditing(true)}
            />
            {isAdmin && (
              <Button
                styleType="primary"
                label="Add New Site"
                size="small"
                type="button"
                onClick={() => redirect('/sites/new')}
              />
            )}
          </>
        ) : (
          <>
            <Button
              disabled={!isDirty || !isValid}
              styleType="primary"
              label="Save Site Settings"
              size="small"
              type="submit"
              onClick={handleSubmit}
            />
            <Button
              styleType="danger"
              label="Cancel"
              size="small"
              onClick={handleCancel}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default SettingsPageHeader
